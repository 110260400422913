import React, { useCallback, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { Box, Typography, Button, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { editAdmin } from 'src/ApiCalls/AdminApiCalls';
import ImageUploader from './ImageUploader';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthData } from 'src/reducers/slices/AuthSLice';

const MyProfileAdmin = () => {
  const authData = useSelector((state) => state.Auth.authData);
  const dispatch = useDispatch();
  const [profileImage, setProfileImage] = useState(authData?.profileImg || null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleForm = async (values) => {
    const response = await editAdmin(values, authData.id);
    if (response) {
      navigate('/admin-management');
    }
  };
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t('user.firstNameRequired')),
    lastName: Yup.string().required(t('user.lastNameRequired')),
    email: Yup.string().email(t('user.emailInvalid')),
    companyName: Yup.string().required(t('user.companyNameRequired')),
    accessLevel: Yup.string().required(t('user.accessLevelRequired')),
    isActive: Yup.boolean(),
    password: Yup.string().min(8, t('user.passwordMinLength')),
    confirmPassword: Yup.string().when('password', {
      is: (password) => !!password,
      then: () =>
        Yup.string()
          .min(8, t('user.passwordMinLength'))
          .oneOf([Yup.ref('password'), null], t('user.passwordsMustMatch'))
          .required(t('user.confirmPasswordRequired')),
      otherwise: () => Yup.string(),
    }),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: authData?.first_name || '',
      lastName: authData?.last_name || '',
      email: authData?.email || '',
      companyName: authData?.name || '',
      accessLevel: authData?.type || '',
      password: '',
      confirmPassword: '',
      isActive: authData?.is_active || false,
      mediaId: authData?.media?.id || null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleForm(values);
    },
  });

  const handleReset = useCallback(() => {
    navigate('/admin-management');
  }, [navigate]);

  const handleUploadSuccess = (mediaId) => {
    if (authData) {
      const updatedImage = `/path/to/images/${mediaId}`;
      setProfileImage(updatedImage);
      formik.setFieldValue('mediaId', mediaId);
      const updatedAuthData = { ...authData, media: { ...authData.media, id: mediaId, path_full: updatedImage } };
      dispatch(setAuthData(updatedAuthData));
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            bgcolor: '#ffffff',
            marginTop: { xs: 4, sm: 8 },
            padding: { xs: 3, sm: 6 },
            borderRadius: 6,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ImageUploader currentImage={profileImage} onUploadSuccess={handleUploadSuccess} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
            }}
          >
            <Typography variant="h3"> {t('profilePopover.myProfile')}</Typography>
          </Box>

          <Grid container spacing={2} sx={{ marginTop: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ color: 'black', fontWeight: '450', mb: 1 }}>{t('user.firstName')}:</Typography>
              <TextField
                fullWidth
                id="first-name"
                name="firstName"
                variant="outlined"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ color: 'black', fontWeight: '450', mb: 1 }}>{t('user.lastName')}:</Typography>
              <TextField
                fullWidth
                id="last-name"
                name="lastName"
                variant="outlined"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ color: 'black', fontWeight: '450', mb: 1 }}>{t('user.email')}:</Typography>
              <TextField
                fullWidth
                id="email"
                name="email"
                variant="outlined"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ color: 'black', fontWeight: '450', mb: 1 }}>{t('user.companyName')}:</Typography>
              <TextField
                fullWidth
                id="companyName"
                name="companyName"
                variant="outlined"
                value={formik.values.companyName}
                onChange={formik.handleChange}
                error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                helperText={formik.touched.companyName && formik.errors.companyName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ color: 'black', fontWeight: '450', mb: 1 }}>{t('user.password')}:</Typography>
              <TextField
                fullWidth
                id="password"
                name="password"
                type="password"
                variant="outlined"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ color: 'black', fontWeight: '450', mb: 1 }}>{t('user.confirmPassword')}:</Typography>
              <TextField
                fullWidth
                id="confirm-password"
                name="confirmPassword"
                type="password"
                variant="outlined"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
              />
            </Grid>
            <Box sx={{ mt: 2, ml: 2 }}>
              <Typography sx={{ color: 'black', fontWeight: '450', mb: 1 }}>{t('user.status')}:</Typography>
              <RadioGroup
                aria-label="active"
                name="isActive"
                value={formik.values.isActive ? 'active' : 'inactive'}
                onChange={(event) => {
                  formik.setFieldValue('isActive', event.target.value === 'active');
                }}
                row
              >
                <FormControlLabel value="active" control={<Radio color="primary" />} label={t('user.active')} />
                <FormControlLabel value="inactive" control={<Radio color="primary" />} label={t('user.inactive')} />
              </RadioGroup>
            </Box>
          </Grid>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
          <Button
            variant="outlined"
            onClick={handleReset}
            size="large"
            sx={{ mr: 2, bgcolor: 'white', color: 'black', border: 'white' }}
          >
            {t('user.cancel')}
          </Button>
          <Button type="submit" variant="contained" size="large" startIcon={<CheckIcon />} color="primary">
            {t('user.update')}
          </Button>
        </Box>
      </form>
    </>
  );
};

export default MyProfileAdmin;
