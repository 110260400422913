import axios from 'axios';
import { dispatch, getState } from '../reducers/configureStore';
import {
  setAromatics,
  setBestTimeToConsume,
  setBusinessUsers,
  setCannabinoids,
  setCountries,
  setEffects,
  setGenetics,
  setIllness,
  setMedicalUse,
  setPzn,
  setStrains,
  setTerpenes,
  setPlantsTraits,
} from '../reducers/slices/masterData';
import { errorMessageHandler, handleCatchError, toSnakeCase } from '../utils/helper';

export const masterDataActions = {
  aromatics: setAromatics,
  bestTimeToConsumes: setBestTimeToConsume,
  genetics: setGenetics,
  illness: setIllness,
  pzns: setPzn,
  cannabinoids: setCannabinoids,
  medicalUses: setMedicalUse,
  terpenes: setTerpenes,
  effects: setEffects,
  businessUsers: setBusinessUsers,
  strains: setStrains,
  plantTraits: setPlantsTraits,
  countries: setCountries,
};

export const fetchMasterData = async (searchKey) => {
  const snakeSearchKey = toSnakeCase(searchKey);
  if (getState().masterData[searchKey]?.length) return;

  try {
    let response;
    if (snakeSearchKey === 'illness') {
      response = await axios.post('/illnesses/listing');
    } else {
      response = await axios.post('/master_search/listing', {
        search_key: snakeSearchKey,
      });
    }

    const { data } = response;
    if (data?.status) {
      switch (snakeSearchKey) {
        case 'pzns':
          dispatch(masterDataActions[searchKey](pznDataFormatter(data.data)));
          break;
        case 'illness':
          dispatch(masterDataActions[searchKey](masterDataformatter(data.data)));
          break;
        default:
          dispatch(masterDataActions[searchKey](masterDataformatter(data.data)));
      }
    } else {
      errorMessageHandler(data);
    }
  } catch (error) {
    handleCatchError(error);
  }
};

export const addMasterData = async (field, name, mediaId) => {
  if (!field?.apiKey) return;

  try {
    const state = getState();
    const payload = mediaId
      ? {
          name,
          media_id: mediaId,
          ...(field?.type && { type: field.type }),
        }
      : {
          name,
          ...(field?.type && { type: field.type }),
        };
    const { data } = await axios.post(`/${field.apiKey}`, payload);

    if (data?.status && data.data) {
      const responseData = data.data;
      const newOption = {
        id: responseData.id,
        name: responseData.name,
        type: responseData.type,
        media_url: responseData.media ? responseData.media?.path_full : null,
      };

      // Update the appropriate state based on the apiKey
      if (field.apiKey === 'genetics') {
        dispatch(setGenetics([...state.masterData.genetics, newOption]));
      } else if (field.apiKey === 'strains') {
        dispatch(setStrains([...state.masterData.strains, newOption]));
        return newOption.name;
      } else if (field.apiKey === 'terpenes') {
        dispatch(setTerpenes([...state.masterData.terpenes, newOption]));
      } else if (field.apiKey === 'effects') {
        dispatch(setEffects([...state.masterData.effects, newOption]));
      } else if (field.apiKey === 'aromatics') {
        dispatch(setAromatics([...state.masterData.aromatics, newOption]));
      }

      return newOption;
    } else {
      errorMessageHandler(data);
    }
  } catch (error) {
    handleCatchError(error);
  }
};

const getAlteredMasterDaataKey = (key) => {
  const alteredKeys = {
    primaryTerpenes: 'terpenes',
    secondaryTerpenes: 'terpenes',
    positiveEffect: 'effects',
    negativeEffect: 'effects',
    aromatics: 'aromatics',
    // medicalUse: 'illness',
  };
  return alteredKeys[key] || key;
};

export const editMasterData = (apiKey, id, newName, newCode, newWeight, mediaId) => async (dispatch, getState) => {
  try {
    if (!apiKey || !id) return;

    let orignalKey = null;

    if (apiKey === 'medicalUse') {
      orignalKey = 'illnesses';
    }

    apiKey = getAlteredMasterDaataKey(apiKey);
    const payload =
      apiKey === 'pzns'
        ? { product_code: newCode, weight: newWeight }
        : mediaId
        ? { name: newName, media_id: mediaId }
        : { name: newName };

    const { data } = await axios.put(`/${orignalKey ?? apiKey}/${id}`, payload);
    if (data?.status && data.data) {
      const responseData = data.data;
      let updatedOption = {
        id: responseData.id,
        name: responseData.name,
        type: responseData.type,
        code: responseData.product_code,
        weight: responseData.weight,
        media_url: responseData.media ? responseData.media?.path_full : null,
      };
      if (apiKey === 'pzns') {
        updatedOption = {
          id: updatedOption.id,
          name: `${updatedOption.code} (${updatedOption.weight})`,
          type: updatedOption.type,
        };
      }

      const updatedOptions = getState().masterData[apiKey === 'medicalUse' ? 'illness' : apiKey].map((option) =>
        option.id === updatedOption.id ? updatedOption : option,
      );

      switch (apiKey) {
        case 'genetics':
          dispatch(setGenetics(updatedOptions));
          break;
        case 'strains':
          dispatch(setStrains(updatedOptions));
          break;
        case 'terpenes':
          dispatch(setTerpenes(updatedOptions));
          break;
        case 'effects':
          dispatch(setEffects(updatedOptions));
          break;
        case 'pzns':
          dispatch(setPzn(updatedOptions));
          break;
        case 'medicalUse':
          dispatch(setIllness(updatedOptions));
          break;
        case 'aromatics':
          dispatch(setAromatics(updatedOptions));
          break;
        default:
          break;
      }

      return updatedOption;
    } else {
      errorMessageHandler(data);
    }
  } catch (error) {
    handleCatchError(error);
  }
};

export const deleteMasterData = (apiKey, id) => async (dispatch, getState) => {
  try {
    if (!apiKey) return;
    let originalKey = null;
    let response;

    if (apiKey === 'medicalUse') {
      originalKey = 'illnesses';
      const body = { illness_ids: [id] };
      response = await axios.delete(`/${originalKey}`, { data: body });
    } else {
      apiKey = getAlteredMasterDaataKey(apiKey);
      response = await axios.delete(`/${originalKey ?? apiKey}/${id}`);
    }

    const { data } = response;

    if (data?.status) {
      const deleteOption = getState().masterData[apiKey === 'medicalUse' ? 'illness' : apiKey].filter(
        (option) => option.id !== id,
      );
      switch (apiKey) {
        case 'genetics':
          dispatch(setGenetics(deleteOption));
          break;
        case 'strains':
          dispatch(setStrains(deleteOption));
          break;
        case 'terpenes':
          dispatch(setTerpenes(deleteOption));
          break;
        case 'effects':
          dispatch(setEffects(deleteOption));
          break;
        case 'pzns':
          dispatch(setPzn(deleteOption));
          break;
        case 'medicalUse':
          dispatch(setIllness(deleteOption));
          break;
        case 'aromatics':
          dispatch(setAromatics(deleteOption));
          break;
        default:
          break;
      }
      return id;
    } else {
      errorMessageHandler(data);
    }
  } catch (error) {
    handleCatchError(error);
  }
};

export const addPZNData = async (payload) => {
  try {
    const state = getState();
    const { data } = await axios.post('/pzns', payload);
    if (data?.status && data.data) {
      const responseData = data.data;
      const newOption = {
        id: responseData.id,
        name: `${responseData.product_code} (${responseData.weight})`,
      };
      dispatch(setPzn([...state.masterData.pzns, newOption]));
      return newOption;
    } else {
      errorMessageHandler(data);
    }
  } catch (error) {
    handleCatchError(error);
  }
};

export const masterDataformatter = (dataArray, base_url) => {
  return dataArray.map((data) => ({
    id: data.id,
    name: data.name,
    type: data.type,
    media_url: data.media ? data.media.path_full : null,
  }));
};

export const pznDataFormatter = (dataArray) => {
  return dataArray.map((data) => ({
    id: data.id,
    name: `${data.product_code} (${data.weight})`,
  }));
};

export const listCountries = async () => {
  try {
    if (getState().masterData.countries.length) return;
    const { data } = await axios.get('data/get-countries');
    if (data?.status && data?.data) {
      const responseData = data.data.map((item) => ({
        id: item.id,
        name: item.name,
      }));
      dispatch(setCountries(responseData));
    } else {
      errorMessageHandler(data);
    }
  } catch (error) {
    handleCatchError(error);
  }
};

export const formatUserData = (userData) => {
  const imageUrl = userData?.media?.path_full ? userData.media.path_full : null;

  return {
    id: userData.id,
    first_name: userData.first_name,
    last_name: userData.last_name,
    name: userData.name,
    email: userData.email,
    type: userData.type,
    phoneNumber: userData.phone_number,
    isActive: userData.is_active,
    insured: userData.insured,
    dateOfBirth: userData.date_of_birth,
    mediaId: userData.media_id,
    profileImg: imageUrl,
    subscriptionDetails: userData.userSubscription[0]?.subscription_plan?.name,
  };
};
