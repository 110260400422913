import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
} from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import axios from 'axios';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { handleCatchError } from 'src/utils/helper';
const ImageUploader = ({ currentImage, onUploadSuccess }) => {
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [open, setOpen] = useState(false);

  const fileInputRef = useRef(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file));
      setOpen(true);
    }
  };

  const handleCancel = () => {
    setImageFile(null);
    setImagePreview(null);
    setOpen(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const uploadImage = async () => {
    if (!imageFile) return;

    const formData = new FormData();
    formData.append('file', imageFile);

    try {
      setLoadingUpload(true);
      const response = await axios.post('file/upload', formData, {
        rawRequest: true,
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (event) => {
          const percent = Math.round((event.loaded / event.total) * 100);
          setUploadProgress(percent);
        },
      });

      const mediaId = response.data?.data?.id;
      onUploadSuccess(mediaId);
      setOpen(false);
    } catch (error) {
      handleCatchError(error);
    } finally {
      setLoadingUpload(false);
    }
  };

  return (
    <Box position="relative" display="inline-block">
      <IconButton
        onClick={() => fileInputRef.current.click()}
        sx={{
          position: 'relative',
          width: '90px',
          height: '90px',
          overflow: 'hidden',
        }}
      >
        {currentImage ? (
          <img src={imagePreview || currentImage} alt="dfg" width="90px" height="90px" />
        ) : (
          <AccountCircleIcon style={{ fontSize: 90 }} />
        )}

        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: '30%',
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            zIndex: 1,
          }}
        />

        <CameraAltIcon
          sx={{
            fontSize: 20,
            position: 'absolute',
            top: '85%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
            zIndex: 2,
          }}
        />
      </IconButton>

      <input
        type="file"
        accept=".jpg, .jpeg, .png, .gif, .svg, .JPG, .JPEG, .PNG, .GIF, .SVG"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleImageChange}
      />

      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>{'Profilbild hochladen'}</DialogTitle>
        <DialogContent>
          {imagePreview ? (
            <Box sx={{ maxWidth: '300px', height: 'auto' }}>
              <img src={imagePreview} alt="Preview" style={{ width: '100%', borderRadius: 8 }} />
            </Box>
          ) : null}

          {loadingUpload && (
            <Box display="flex" justifyContent="center" mt={2}>
              <Box sx={{ width: '100%' }}>
                <LinearProgress variant="determinate" value={uploadProgress} />
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>{'Abbrechen'}</Button>
          <Button
            sx={{
              backgroundColor: 'primary.main',
              color: '#fff',
              '&:hover': {
                backgroundColor: 'primary.main',
              },
              '&:disabled': {
                backgroundColor: '#ccc',
              },
            }}
            onClick={uploadImage}
            disabled={loadingUpload || !imageFile}
          >
            {'Hochladen'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ImageUploader;
