import axios from 'axios';
import { dispatch } from 'src/reducers/configureStore';
import { errorToast, successToast } from 'src/shared/Toast';
import { setHideBeatLoader, setShowBeatLoader } from 'src/reducers/slices/AlertsSlice';
import moment from 'moment';
import { setAuthData } from 'src/reducers/slices/AuthSLice';
import { handleCatchError } from 'src/utils/helper';
import { formatUserData } from './MasterSearchApiCalls';

export const UserType = {
  SUPER_ADMIN: 1,
  STANDARD_USER: 2,
  BUSINESS_ADMIN: 3,
  SELLER: 4,
};

const adminFormatter = (admin) => {
  return {
    id: admin.id,
    first_name: admin.first_name,
    last_name: admin.last_name,
    name: admin.name,
    email: admin.email,
    companyName: admin.name,
    type:
      admin.type === UserType.STANDARD_USER
        ? 'Standard User'
        : admin.type === UserType.BUSINESS_ADMIN
        ? 'Business User'
        : admin.type === UserType.SELLER
        ? 'Seller'
        : null,
    access_level: admin.type,
    is_active: admin.is_active ? 1 : 0,
    active_status: admin.is_active,
    subscription_details:
      admin.userSubscription.length > 0
        ? admin.userSubscription.map((item) => ({
            start_date: moment(item.start_date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            end_date: moment(item.end_date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            amount: item.subscription_plan.amount,
            name: item.subscription_plan.name,
            status: item.status,
            currency: item.subscription_plan.currency,
          }))
        : [],
    mediaUrl: admin.media?.path_full,
  };
};
const adminsFormatter = (admins) => {
  return admins.map((admin) => adminFormatter(admin));
};
export const createAdmin = async (values) => {
  const payload = {
    first_name: values.firstName,
    last_name: values.lastName,
    email: values.email,
    companyName: values.name,
    password: values.password,
    password_confirmation: values.confirmPassword,
    is_active: values.isActive,
    type: values.accessLevel,
    media_id: values.mediaId || null,
  };
  try {
    const { data } = await axios.post('/users/seller', payload);

    if (data?.status) {
      successToast(data.message);
      return true;
    } else {
      errorToast(data.errors[0].message);
    }
  } catch (error) {
    handleCatchError('error', error);
    errorToast('Something went wrong!');
  }
};

export const ListAdmins = async (params) => {
  const body = {
    ...params,
  };

  try {
    dispatch(setShowBeatLoader());
    const { data } = await axios.post('/users/listing', body);
    if (data?.status) {
      return {
        ...paginationFormator(data),
        data: adminsFormatter(data.data) ?? [],
      };
    } else {
      errorToast(data?.message);
    }
  } catch (error) {
    errorToast('Listing failed');
    handleCatchError('Listing failer failed', error);
  } finally {
    dispatch(setHideBeatLoader());
  }
};

export const deleteUser = async (userId) => {
  try {
    dispatch(setShowBeatLoader());
    const response = await axios.delete(`/users/${userId}`);
    if (response?.data?.status) {
      successToast(response.data.message ?? 'User Deleted Successfully');
      return true;
    } else {
      errorToast(response?.data?.message);
    }
  } catch (e) {
    handleCatchError('error', e);

    errorToast('An error occurred while deleting the user');
  } finally {
    dispatch(setHideBeatLoader());
  }
};

export const getUserByToken = async () => {
  try {
    const response = await axios.get(`/users/user_details`);
    if (response?.data?.status) {
      const formattedUserData = formatUserData(response.data.data);
      dispatch(setAuthData(formattedUserData));
      return true;
    }
  } catch (e) {
    handleCatchError('error', e);
    errorToast('An error occurred while getting user details ');
  }
};

const formatAdminPayload = (values) => ({
  first_name: values.firstName,
  last_name: values.lastName,
  email: values.email,
  is_active: values.isActive,
  type: values.accessLevel,
  media_id: values.mediaId || null,
  ...(values.password && { password: values.password }),
  ...(values.confirmPassword && { password_confirmation: values.confirmPassword }),
});

export const editAdmin = async (values, id) => {
  const payload = formatAdminPayload(values);
  try {
    const { data } = await axios.put(`/users/admin/${id}`, payload);
    if (data?.status) {
      successToast(data.message);
      return true;
    } else {
      errorToast(data.message);
    }
  } catch (error) {
    handleCatchError('error', error);
  }
};

export const getAdminById = async (id) => {
  try {
    const { data } = await axios.get(`/users/admin/${id}`);
    if (data?.status) {
      successToast(data.message);
      return formatUserData(data.data);
    }
  } catch (error) {
    handleCatchError('error', error);
  }
};

export const paginationFormator = (data) => {
  return {
    totalPages: data?.total_page_count ?? 1,
    currentPage: data?.page ?? 1,
    pageSize: data?.page_size ?? 10,
  };
};
